<template>
    <div class="main-page">
        <div class="container px-3">
            <div class="row">
                <div class="col-md-5 col-md-push-7 vh-100 d-flex">
                    <div class="form-login w-100">
                        <form @submit.prevent="submit">
                          <div class="d-flex flex-column align-items-center">
                              <div class="mb-4">
                                  <img src="@/assets/icon/otp.svg" alt="">
                              </div>
                              <span class="fs-24 fw-700 color-neutral-900">
                                Verifikasi
                              </span>
                              <p class="fs-14 fw-400 color-neutral-600 text-center px-3">
                                Silahkan masukkan kode OTP yang sudah dikirimkan ke email anda.
                              </p>
                          </div>
                            <label>Masukkan Kode</label>
                            <div class="input-group mb-3">
                                <input type="text" v-model="payload.otp" maxlength="4" class="form-control" placeholder="_ _ _ _" :class="{ 'is-invalid': errorMessage }" style="letter-spacing: 10px;">
                                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errorMessage }">
                                    <div class="show-password" type="button" @click="payload.otp = ''">
                                        <i class="ri-close-circle-fill fs-24"></i>
                                    </div>
                                </span>
                                <span class="invalid-feedback">{{ errorMessage }}</span>
                            </div>

                            <div class="w-100 text-center">
                              <button v-if="loading.checkOtp" type="button" class="btn btn-primary w-100" disabled>
                                <span class="fs-16 fw-600 text-white">Loading</span> <span class="spinner-border" role="status"></span>
                              </button>

                              <button v-else type="submit" class="btn btn-primary w-100" :disabled="!payload.otp">
                                  <span class="fs-16 fw-600 text-white">Kirim</span>
                              </button>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-center mt-3">
                              <span class="d-block fs-14 fw-400 color-neutral-600 mb-3">{{ (loading.otp) ? 'Mengirim ulang..':'Belum menerima kode ?' }}</span>
                              <span v-if="countDown > 0" class="fs-14 fw-700 color-neutral-900">{{ countDown }}</span>
                              <a v-else-if="!loading.otp" href="#" class="fs-14 fw-700 color-secondary-500" @click="resend">
                                Kirim Ulang
                              </a>
                              <span v-else class="spinner-border align-middle" role="status"></span>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-7 order-first order-md-last d-none d-md-block">
                    <div class="frame-image vh-100 d-flex align-items-center">
                        <div class="ps-5">
                            <img src="@/assets/illustration.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/plugins/axios'
import { loading, sendOtp, login, dataRegister } from '@/core/auth'
import { analytics } from '@/core/firebase-messaging'

export default {
  setup () {
    const router = useRouter()
    const errorMessage = ref('')
    const countDown = ref(90)
    const payload = reactive({
      email: useRoute().params.email,
      otp: null,
      token_login: null
    })

    const countDownTimer = () => {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1
          countDownTimer()
        }, 1000)
      }
    }

    const submit = async () => {
      try {
        loading.checkOtp = true
        analytics.logEvent('check_otp')
        const result = await axios.post('app/verifikasiotp', payload)

        if (!result.data.success) {
          errorMessage.value = result.data.message

          analytics.logEvent('check_otp_failed', result.data)
          return
        }

        loading.login = true
        const { status, message } = await login({
          email: dataRegister.email,
          password: dataRegister.password,
          token_login: null // sementara null aja dulu
        })

        analytics.logEvent('check_otp_success')

        // Jika sukses
        if (status) {
          // redirect ke home
          return router.push({ name: 'RegisterSuccess' })
        }

        errorMessage.value = message
      } catch (error) {
        console.error('error cek OTP = ', error)
        errorMessage.value = error.response.data.message
        analytics.logEvent('check_otp_failed', error.response.data)
      } finally {
        loading.checkOtp = false
      }
    }

    const resend = async () => {
      try {
        analytics.logEvent('resend_otp')

        const result = await sendOtp({
          email: payload.email
        })

        if (!result.status) {
          errorMessage.value = result.message
        }

        // Reset timer
        countDown.value = 90
        countDownTimer()
      } catch (error) {
        errorMessage.value = error.response.data.message
      }
    }

    onMounted(() => {
      countDownTimer()
    })

    return {
      payload,
      submit,
      loading,
      errorMessage,
      countDown,
      resend
    }
  }
}
</script>

<style scoped>
.form-login {
  padding-top: 25%;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

.btn-login {
  border: none;
  padding: 10px 20px;
}

.frame-image {
  position: absolute;
  background: #007a9826;
  width: 56%;
}

.frame-image img {
  width: 90%;
  height: auto;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  animation-duration: 1.5s
}

@media (min-width: 360px) and (max-width: 767px) {
  .col-md-5 {
    padding-right: 0px !important;
  }

  .form-login {
    padding-right: 1rem !important;
  }

  .col-md-7 {
    padding-left: 0px;
  }
  .frame-image {
    position: relative;
    background: #007a9826;
    width: 100%;
  }
}
</style>
